
import { ref, onMounted } from 'vue';
import ScrollReveal from "scrollreveal";
import animal from 'animejs/lib/anime.es.js';
// import Isotope from 'isotope-layout';
import { useTransition, TransitionPresets } from '@vueuse/core';
export default {
  setup() {

    //数字增长
    const num1 = ref(0);
    const num2 = ref(0);
    const num3 = ref(0);
    const srcList = [
      'https://huiying-yunnan.oss-cn-chengdu.aliyuncs.com/huiying/websitepicture/brand-img1.jpg',
      'https://huiying-yunnan.oss-cn-chengdu.aliyuncs.com/huiying/websitepicture/brand-img2.jpg',
      'https://huiying-yunnan.oss-cn-chengdu.aliyuncs.com/huiying/websitepicture/brand-img3.jpg',
      'https://huiying-yunnan.oss-cn-chengdu.aliyuncs.com/huiying/websitepicture/brand-img4.jpg',
      'https://huiying-yunnan.oss-cn-chengdu.aliyuncs.com/huiying/websitepicture/brand-img5.jpg',
      'https://huiying-yunnan.oss-cn-chengdu.aliyuncs.com/huiying/websitepicture/brand-img6.jpg',
    ];
    const productImg = ref<HTMLElement | null>(null);
    const brandLeft = ref<HTMLElement | null>(null);
    const brandRight = ref<HTMLElement | null>(null);
    const advantagesBox = ref<HTMLElement | null>(null);
    const duration = 2000;
    const num1Output = useTransition(num1, {
      duration: duration,
      transition: TransitionPresets.easeOutExpo
    });
    num1.value = 2017;
    const num2Output = useTransition(num2, {
      duration: duration,
      transition: TransitionPresets.easeOutExpo
    });
    num2.value = 500;
    const num3Output = useTransition(num3, {
      duration: duration,
      transition: TransitionPresets.easeOutExpo
    });
    num3.value = 17;

    const aboutCardRowOption = {
      delay: 200,
      reset: true,
      distance: "1.52rem",
      duration: 300,
      easing: "ease-in-out",
      origin: "top",
      indterval: 400,
    };

    const aboutImgOption = {
      delay: 200,
      reset: true,
      distance: "1.52rem",
      duration: 300,
      easing: "ease-in-out",
      origin: "bottom",
      indterval: 400,
    };


    const growth2 = () => {
      animal({
        targets: productImg.value,
        easing: 'easeInOutQuad',
        translateX: [-600, 0],
        duration: 600,
      });
    }
    onMounted(() => {
      ScrollReveal().reveal('.about-img', { ...aboutImgOption });
      ScrollReveal().reveal('.about-card-row', { ...aboutCardRowOption });
      ScrollReveal().reveal('.product-img', { reset: true, beforeReveal: growth2 });
    })
    return {
      num1Output,
      num2Output,
      num3Output,
      productImg,
      brandLeft,
      brandRight,
      advantagesBox,
      srcList
    }
  }
}

